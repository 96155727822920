document.addEventListener("DOMContentLoaded", () => {
  const tabNavigationButtons = document.querySelectorAll(
    ".tabs-nav .tab-nav--item"
  );
  const tabContents = document.querySelectorAll(".tabs .tab-content");

  function setActiveTab(targetTab, scrollTo = true) {
    tabContents.forEach((tab) => {
      if (tab.id === targetTab) {
        tab.classList.add("active");
        if (window.innerWidth <= 768 && scrollTo) {
          const { left } = tab.children[0].getBoundingClientRect();
          const parent =  tab.parentElement;
         parent.scrollLeft += left - parent.getBoundingClientRect().left;
        }
        return;
      }
      tab.classList.remove("active");
    });
    tabNavigationButtons.forEach((nav) => {
      if (nav.dataset.target.includes(targetTab)) {
        nav.classList.add("active");
        if (window.innerWidth <= 768) {
          const { left } = nav.getBoundingClientRect();
          const parent =  nav.parentElement;
         parent.scrollLeft += left - parent.getBoundingClientRect().left;
        }
        return;
      }
      nav.classList.remove("active");
    });
  } 

  const activateTab = (event) => {
    const target = event.target.closest(".tab-nav--item");
    if (!target) return;
    const targetTab = target.dataset.target.replace("#", "");
    setActiveTab(targetTab);
  };

  if (tabNavigationButtons.length > 0) {
    tabNavigationButtons.forEach((nav) => {
      nav.addEventListener("click", activateTab);
    });
  }

  const options = {
    rootMargin: "0px",
    threshold: 0.5,
  };

function observerCallback(entries, observer) {
  entries.forEach(entry => {
      if(entry.isIntersecting) {
        const { id } = entry.target;
        setActiveTab(id, false)
      }
  });
};
  
  const observer = new IntersectionObserver(observerCallback, options);
  tabContents.forEach((t) => observer.observe(t));
}, { once: true });
